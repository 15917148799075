import { useCallback } from "react";
import { useAppDispatch } from "./hooks";
import { setOverviewMap } from "../store/appSlice";

import {
  ColumnItem,
  OrderColumnItem,
} from "../../documentSet/interfaces/overview";
import {
  updateUserArchiveOrderColumns,
  updateUserOverviewOrderColumns,
} from "../../user/store/userSlice";
import useUserSettings from "../../user/hooks/useUserSettings";
import { User, UserSettings } from "../../user";
import { useLazyGetAppConfigQuery } from "../store/appApi";
import { setHasColumnItemsFetchingError } from "../../documentSet/store/documentSetSlice";

const mergeUniqueObjects = (
  arr1: Array<ColumnItem>,
  arr2: Array<OrderColumnItem>
): Array<OrderColumnItem> => {
  // Reform arr1 to correct format
  const formed = arr1.map(
    (o): OrderColumnItem => ({
      key: o.key,
      title: o.key,
      show: true,
    })
  );

  const mergedMap = new Map();

  // Function to add an object to the map if it doesn't exist
  const addObjectToMap = (obj: OrderColumnItem) => {
    if (!mergedMap.has(obj.key)) {
      mergedMap.set(obj.key, obj);
    }
  };

  // Add elements from arr2 to the map
  arr2.forEach(addObjectToMap);

  // Add elements from mapped arr1 to the map
  formed.forEach(addObjectToMap);

  // Convert the map values back to an array
  return Array.from(mergedMap.values());
};

const useAppConfig = () => {
  const dispatch = useAppDispatch();

  const { updateUserSettings } = useUserSettings();

  const [getAppConfig] = useLazyGetAppConfigQuery();

  return useCallback(
    async (user: User, userSettings: UserSettings) => {
      const { overviewOrderColumns, archiveOrderColumns } = userSettings;

      if (!user?.inputIds?.length) {
        return;
      }

      try {
        const { columnItems } = await getAppConfig(user.inputIds).unwrap();

        if (columnItems?.length) {
          const sortedColumnItems = [...columnItems].sort(
            (a, b) => a.defaultIndex - b.defaultIndex
          );

          const overviewMerged = mergeUniqueObjects(
            sortedColumnItems,
            overviewOrderColumns || []
          );
          const archiveMerged = mergeUniqueObjects(
            sortedColumnItems,
            archiveOrderColumns || []
          );

          dispatch(updateUserOverviewOrderColumns(overviewMerged));
          dispatch(updateUserArchiveOrderColumns(archiveMerged));
          dispatch(setOverviewMap(sortedColumnItems));
          dispatch(setHasColumnItemsFetchingError(false));

          const updatedUserSettings = {
            ...userSettings,
            overviewOrderColumns: overviewMerged,
            archiveOrderColumns: archiveMerged,
          };

          if (
            JSON.stringify(userSettings) !== JSON.stringify(updatedUserSettings)
          ) {
            await updateUserSettings(user, updatedUserSettings);
          }
        }
      } catch (e: any) {
        dispatch(setHasColumnItemsFetchingError(true));
        console.log(e);
      }
    },
    [dispatch, updateUserSettings, getAppConfig]
  );
};

export default useAppConfig;
