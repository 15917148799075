import { useAuth0 } from "@auth0/auth0-react";
import http from "../../common/utilities/HttpModule";
import { useCallback, useEffect, useRef } from "react";
import { changeIsTokenRefreshing, logout } from "../store/userSlice";
import { useAppDispatch } from "../../app";
import useAccessTokenRefresh from "./useAccessTokenRefresh";
import useAuthentication from "./useAuthentication";
import useAuthenticationCookie from "./useAuthenticationCookie";

export const COOKIE_NAME = "accessToken";

const useAuth0thenticate = (shouldRun = false, alwaysGetNewToken = false) => {
  const hasFetchedAccessToken = useRef(false);
  const dispatch = useAppDispatch();

  const {
    getAccessTokenSilently,
    logout: auth0Logout,
    isAuthenticated,
  } = useAuth0();

  const { removeCookie } = useAuthenticationCookie();

  const authenticateUser = useAuthentication();

  const fetchTokenAndMetaData = useCallback(async () => {
    if (!isAuthenticated) {
      dispatch(changeIsTokenRefreshing(false));
      return;
    }

    try {
      const cacheModeValue = alwaysGetNewToken ? "off" : "on";
      const accessToken = await getAccessTokenSilently({
        cacheMode: cacheModeValue,
      });

      await authenticateUser(accessToken);

      hasFetchedAccessToken.current = true;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(changeIsTokenRefreshing(false));
    }
  }, [
    alwaysGetNewToken,
    getAccessTokenSilently,
    authenticateUser,
    isAuthenticated,
    dispatch,
  ]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("timeoutId");
    removeCookie(COOKIE_NAME);
    delete http.axios.defaults.headers.common.Authorization;
    dispatch(logout());
    auth0Logout({ logoutParams: { returnTo: window.location.origin } });
  }, [auth0Logout, removeCookie, dispatch]);

  useAccessTokenRefresh(shouldRun, authenticateUser, handleLogout);

  useEffect(() => {
    if (!shouldRun) {
      return;
    }

    if (hasFetchedAccessToken.current) {
      return;
    }

    fetchTokenAndMetaData();
  }, [fetchTokenAndMetaData, shouldRun]);

  return { handleLogout, fetchTokenAndMetaData };
};

export default useAuth0thenticate;
