import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useAppSelector } from "../../app";
import { useActiveDocumentSet } from "../../documentSet";
import useActiveDocument from "../../document/hooks/useActiveDocument";
import useMeasure from "react-use-measure";

const useAnnotationSideControls = (documentSetId?: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertHeight, setAlertHeight] = useState(0);
  const [heightFooter, setHeightFooter] = useState(0);

  const [currentHeight, setCurrentHeight] = useState(0);

  const annotationSideControlsClassnames = classNames({
    "transition-view": true,
    "in-detail-view": true,
  });

  const userSettings = useAppSelector(
    (state) => state.userReducer.userSettings
  );

  const { isTableControlsResizing, tableControlsHeight } = useAppSelector(
    (state) => state.annotationTableControlsReducer
  );

  const { isTokenRefreshing } = useAppSelector((state) => state.userReducer);

  const { fetchDocumentActiveDocumentSet, activeDocumentSet } =
    useActiveDocumentSet();

  const { fetchDocument, setActiveDocument } = useActiveDocument();

  const [containerRef, { height: heightContainer }] = useMeasure();

  const fetchDocumentSetRef = useRef(fetchDocumentActiveDocumentSet);

  useEffect(() => {
    fetchDocumentSetRef.current = fetchDocumentActiveDocumentSet;
  }, [fetchDocumentActiveDocumentSet]);

  useEffect(() => {
    const controller = new AbortController();

    if (documentSetId && !isTokenRefreshing) {
      fetchDocumentSetRef.current(documentSetId, controller.signal, true);
    }

    return () => controller.abort();
  }, [documentSetId, isTokenRefreshing]);

  useEffect(() => {
    const controller = new AbortController();
    if (!activeDocumentSet?.documents?.length) {
      setActiveDocument();
      return;
    }

    fetchDocument(
      activeDocumentSet.documents[0].id,
      activeDocumentSet.status,
      controller.signal
    );

    return () => controller.abort();
    // The useEffect triggers to many times due to fetchDocument, so we exclude it from the dependency array
    // eslint-disable-next-line
  }, [activeDocumentSet?.documents, setActiveDocument]);

  // Avoid setting the actual height during table control resizing to prevent excessive rendering.
  useEffect(() => {
    if (!isTableControlsResizing) {
      setCurrentHeight(heightContainer);
    }
  }, [isTableControlsResizing, tableControlsHeight, heightContainer]);

  return {
    isLoading,
    setIsLoading,
    alertHeight,
    heightFooter,
    setHeightFooter,
    annotationSideControlsClassnames,
    userSettings,
    activeDocumentSet,
    setAlertHeight,
    currentHeight,
    containerRef,
  };
};

export default useAnnotationSideControls;
