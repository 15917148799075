import React, { FunctionComponent, useEffect } from "react";
import Routes from "./routes";
import dayjs from "dayjs";
import "./App.scss";
import { useDocumentTypes } from "../documentTypes";
import { useTranslation } from "react-i18next";
import { hotjarInitialized } from "../hotjar";
import { hotjar } from "react-hotjar";
import Maintenance, { useMaintenance } from "../maintenance";
import { useAppSelector } from "./hooks/hooks";
import { useAuth0IdleTimer, WelcomePage } from "../user";
import "bootstrap-icons/font/bootstrap-icons.css";
import OnboardingTour from "../tours/components/OnboardingTour";
import useIsAuthenticated from "../user/hooks/useIsAuthenticated";
import useJwtAuthenticate from "../user/hooks/useJwtAuthenticate";
import useAnnotatorReset from "../annotator/hooks/useAnnotatorReset";
import ShortcutHelp from "../help/components/shortcut/ShortcutHelp";

const App: FunctionComponent = () => {
  useJwtAuthenticate();
  useAuth0IdleTimer();
  useAnnotatorReset();

  const user = useAppSelector((state) => state.userReducer.user);

  const { isAuthenticated, isLoading } = useIsAuthenticated();

  const maintenance = useMaintenance();
  const { fetchDocumentTypes } = useDocumentTypes();

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    dayjs.locale(language);
  }, [language]);

  useEffect(() => {
    if (!isAuthenticated || isLoading) {
      return;
    }

    if (user?.inputIds?.length) {
      fetchDocumentTypes(user.inputIds);
    }
  }, [fetchDocumentTypes, user, isAuthenticated, isLoading]);

  useEffect(() => {
    if (user && hotjarInitialized) {
      hotjar.identify(user.username, user);
    }
  }, [user]);

  if (maintenance) {
    return <Maintenance />;
  }

  if (user?.showWelcome) {
    return <WelcomePage />;
  }

  return (
    <div className="app__container">
      <ShortcutHelp />
      <OnboardingTour />
      <Routes />
    </div>
  );
};

export default App;
