import React, { FC } from "react";
import "./AccountPopover.scss";
import { CustomAvatar } from "../../../avatar";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import AppVersion from "../../../appVersion/AppVersion";
import { Link, useLocation } from "react-router-dom";
import useAuth0thenticate from "../../../../user/hooks/useAuth0thenticate";
import useSideMenuClick from "../../hooks/useSideMenuClick";
import { useAppDispatch, useAppSelector } from "../../../../app";
import useJwtAuthenticate from "../../../../user/hooks/useJwtAuthenticate";
import { changeModalStateForKey } from "../../../../app/store/appSlice";
import { shortcutModalKey } from "../../../../help/components/shortcut/ShortcutHelp";

type TitleProps = {
  avatar: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  callback: () => void;
};
export const AccountPopoverTitle: FC<TitleProps> = ({
  avatar,
  firstName,
  lastName,
  username,
  callback,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("accountPopover");
  const { pathname } = useLocation();

  const onSideMenuClickChecks = useSideMenuClick();

  return (
    <div className="account-popover__container">
      <div className="account-popover__user">
        <CustomAvatar size={70} src={avatar} />
        <div className="account-popover__user-details">
          <h2>{`${firstName} ${lastName}`}</h2>
          <span>{username}</span>
        </div>
      </div>
      <Divider className="account-popover__divider" />
      <div
        className="account-popover__item link"
        onClick={() => {
          callback();
          dispatch(changeModalStateForKey(shortcutModalKey));
        }}
      >
        <span className="account-popover__item-icon">
          <i className="bi bi-arrow-up-right-square" />
        </span>
        <span className="account-popover__flex-wrapper">
          <label className="account-popover__item-label">
            {t("shortcutHelp")}
          </label>
        </span>
      </div>
      <Divider className="account-popover__divider" />
      <div className="account-popover__item link" onClick={callback}>
        <Link
          to="/user/settings"
          state={{ previousPath: pathname }}
          onClick={() => onSideMenuClickChecks(null)}
        >
          <span className="account-popover__item-icon">
            <i className="bi bi-person" />
          </span>
          <label className="account-popover__item-label">
            {t("personalSettingsLabel")}
          </label>
        </Link>
      </div>
      <div className="account-popover__item link" onClick={callback}>
        <Link
          to="/user/email-templates"
          state={{ previousPath: pathname }}
          onClick={() => onSideMenuClickChecks(null)}
        >
          <span className="account-popover__item-icon">
            <i className="bi bi-envelope" />
          </span>
          <label className="account-popover__item-label">
            {t("emailTemplatesLabel")}
          </label>
        </Link>
      </div>
      <div className="account-popover__item link" onClick={callback}>
        <Link
          to="/releases"
          state={{ previousPath: pathname }}
          onClick={() => onSideMenuClickChecks(null)}
        >
          <span className="account-popover__item-icon">
            <i className="bi bi-tags" />
          </span>
          <label className="account-popover__item-label">Releases</label>
        </Link>
      </div>
      <div className="account-popover__item link">
        <a
          href="https://racc-group.notion.site/FAQ-236c463c371f4a59ad250e05b8b978b3?pvs=4"
          target="_blank"
          rel="noreferrer"
        >
          <span className="account-popover__item-icon">
            <i className="bi bi-question-circle" />
          </span>
          <span className="account-popover__flex-wrapper">
            <label className="account-popover__item-label">
              {t("helpLabel")}
            </label>
            <i className="bi bi-box-arrow-up-right" />
          </span>
        </a>
      </div>
      <Divider className="account-popover__divider no-margin" />
    </div>
  );
};

export const AccountPopoverButton: FC = () => {
  const { t } = useTranslation("accountPopover");

  const { handleLogout } = useAuth0thenticate();
  const handleJwtLogout = useJwtAuthenticate();

  const isJwtLoggedIn = useAppSelector(
    (state) => state.userReducer.isJwtLoggedIn
  );

  if (isJwtLoggedIn) {
    return (
      <>
        <div
          className="account-popover__logout-button"
          onClick={handleJwtLogout}
        >
          <i className="bi bi-box-arrow-right" />
          <span>{t("logoutButton")}</span>
        </div>
        <AppVersion className="account-popover__version" />
      </>
    );
  }

  return (
    <>
      <div className="account-popover__logout-button" onClick={handleLogout}>
        <i className="bi bi-box-arrow-right" />
        <span>{t("logoutButton")}</span>
      </div>
      <AppVersion className="account-popover__version" />
    </>
  );
};
