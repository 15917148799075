import { useCallback, useEffect, useMemo, useState } from "react";
import { Annotation } from "../interfaces/annotation";
import { useAppSelector } from "../../app";
import annotationToScrollToSelector from "../selectors/annotationToScrollToSelector";

const useScrollToAnnotation = (annotation: Annotation) => {
  const scrollToAnnotation = useAppSelector(annotationToScrollToSelector);
  const lastAnnotation = useAppSelector(
    (state) => state.annotationReducer.lastAnnotation
  );

  const [hasScrolled, setHasScrolled] = useState(false);

  const scrollAnnotationIntoView = useCallback(
    (annotation: Annotation) => {
      setHasScrolled(false);

      if (!scrollToAnnotation) {
        return;
      }

      if (
        lastAnnotation &&
        lastAnnotation.id === annotation.id &&
        lastAnnotation.index === annotation.index
      ) {
        return;
      }

      if (
        scrollToAnnotation.id === annotation.entity.id &&
        scrollToAnnotation.index === annotation.index
      ) {
        const container = document.getElementById("annotator-pages-container");
        const target = document.getElementById(
          `${annotation.id}-${annotation.index}-0`
        );

        if (!container || !target) {
          return;
        }

        const containerRect = container.getBoundingClientRect();
        const targetRect = target.getBoundingClientRect();

        const isInView =
          targetRect.top >= containerRect.top &&
          targetRect.bottom <= containerRect.bottom;

        if (isInView) {
          setHasScrolled(true);
          return;
        }

        const containerScrollTop = container.scrollTop;
        const targetOffsetTop =
          targetRect.top + containerScrollTop - containerRect.top;

        const scrollTop = Math.max(
          0,
          targetOffsetTop - container.clientHeight / 2
        );

        container.scrollTo({
          top: scrollTop,
          left: 0,
          behavior: "smooth",
        });

        setHasScrolled(true);
      }
    },
    [scrollToAnnotation, lastAnnotation]
  );

  useEffect(() => {
    scrollAnnotationIntoView(annotation);
  }, [annotation, scrollAnnotationIntoView]);

  return useMemo(() => {
    if (hasScrolled) {
      return "pulsate-fwd";
    }

    return "";
  }, [hasScrolled]);
};

export default useScrollToAnnotation;
