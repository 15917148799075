import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getVariableFromEnv } from "../../common/utilities/env";
import { getHeadersWithAuthorization } from "../../common/reduxQuery/helpers";
import { IStpEntityResponse, IStpSupplierResponse } from "../interfaces/stp";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

export const organizationApi = createApi({
  reducerPath: "organizationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/api/v1/stp`,
    prepareHeaders: (headers) => getHeadersWithAuthorization(headers),
  }),
  endpoints: (build) => ({
    fetchStpSuppliers: build.query<
      Array<IStpSupplierResponse>,
      { organizationId: string; inputIds: Array<string> }
    >({
      query: ({ organizationId, inputIds }) => ({
        url: `/suppliers?organization-id=${organizationId}&input-ids=${inputIds}`,
        method: "GET",
      }),
    }),
    insertStpSupplier: build.query<IStpSupplierResponse, IStpSupplierResponse>({
      query: (stpSupplier) => ({
        url: `/suppliers`,
        body: {
          ...stpSupplier,
        },
        method: "POST",
      }),
    }),
    removeStpSuppliers: build.query<void, any>({
      query: (data) => ({
        url: `/suppliers`,
        body: {
          ...data,
        },
        method: "DELETE",
      }),
    }),
    fetchStpEntities: build.query<Array<IStpEntityResponse>, string>({
      query: (docTypeId) => ({
        url: `/entities?document-type-id=${docTypeId}`,
        method: "GET",
      }),
    }),
    addOrUpdateStpEntity: build.query<IStpEntityResponse, IStpEntityResponse>({
      query: (stpEntity) => ({
        url: `/entities`,
        body: {
          ...stpEntity,
        },
        method: "POST",
      }),
    }),
    removeStpEntities: build.query<void, any>({
      query: (data) => ({
        url: `/entities`,
        body: {
          ...data,
        },
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyFetchStpSuppliersQuery,
  useLazyInsertStpSupplierQuery,
  useLazyRemoveStpSuppliersQuery,
  useLazyFetchStpEntitiesQuery,
  useLazyAddOrUpdateStpEntityQuery,
  useLazyRemoveStpEntitiesQuery,
} = organizationApi;
