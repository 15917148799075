import React, { FC, useMemo } from "react";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app";
import { DocumentSetFetchingErrorType } from "../../interfaces/documentSet";

const FetchingErrorAlert: FC = () => {
  const { t } = useTranslation("overview", {
    keyPrefix: "fetchingErrorType",
  });

  const { fetchingErrorType, hasColumnItemsFetchingError } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const { isTokenRefreshing } = useAppSelector((state) => state.userReducer);

  const message = useMemo(() => {
    if (
      (!fetchingErrorType && !hasColumnItemsFetchingError) ||
      isTokenRefreshing
    ) {
      return null;
    }

    if (fetchingErrorType === DocumentSetFetchingErrorType.INVALID_QUERY) {
      return t("invalidQuery");
    } else if (fetchingErrorType === DocumentSetFetchingErrorType.OTHER_ERROR) {
      return t("otherError");
    } else if (hasColumnItemsFetchingError) {
      return t("columnItemsError");
    }

    return null;
  }, [fetchingErrorType, t, hasColumnItemsFetchingError, isTokenRefreshing]);

  if (
    (!fetchingErrorType && !hasColumnItemsFetchingError) ||
    isTokenRefreshing
  ) {
    return null;
  }

  return <Alert message={message} type="error" showIcon />;
};

export default FetchingErrorAlert;
