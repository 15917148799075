import { useCallback } from "react";
import { fetchUserData } from "../utils/http";
import {
  parseJwt,
  parseSettings,
  removeUnknownInputIdsFromFilters,
} from "../utils/utils";
import http from "../../common/utilities/HttpModule";
import { User, UserSettingsSorters } from "../interfaces/interfaces";
import { login, updateUserSettings } from "../store/userSlice";
import {
  ARCHIVE_SORTER_KEY,
  OVERVIEW_SORTER_KEY,
} from "../../common/dataRefining";
import {
  setActiveSorter,
  setFilterArrayArchive,
  setFilterArrayOverview,
  setShouldInitFilteringData,
} from "../../common/dataRefining/store/dataRefiningSlice";
import { DocumentSetColumns } from "../../documentSet/interfaces/documentSet";
import Sentry from "../../sentry";
import { COOKIE_NAME } from "./useAuth0thenticate";
import { useAppDispatch } from "../../app";
import { useTranslation } from "react-i18next";
import useAppConfig from "../../app/hooks/useAppConfig";
import useAuthenticationCookie from "./useAuthenticationCookie";
import { setHasColumnItemsFetchingError } from "../../documentSet/store/documentSetSlice";

const useAuthentication = () => {
  const dispatch = useAppDispatch();

  const { setCookie } = useAuthenticationCookie();

  const {
    i18n: { changeLanguage },
  } = useTranslation("personalPage");

  const fetchAndSetOverviewConfig = useAppConfig();

  return useCallback(
    async (accessToken: string) => {
      try {
        const { data } = await fetchUserData(accessToken);
        const parsedSettingsData = parseSettings(data.metadata);

        const organizationInputs = data.organizationSources.map((source) =>
          source.id.toLowerCase()
        );

        const inputPermissions = data.userProfile.permissions.filter((input) =>
          organizationInputs.includes(input.toLowerCase())
        );

        setCookie(COOKIE_NAME, accessToken, {
          expires: new Date(parseJwt(accessToken).exp * 1000),
        });
        http.axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        const userLogin: User = {
          id: data.userProfile.id,
          username: data.username,
          firstName: data.userProfile.firstName,
          lastName: data.userProfile.lastName,
          email: data.userProfile.email,
          inputIds: inputPermissions,
          access: data.userProfile.roles,
          avatar: data.metadata.picture!,
          showWelcome: data.metadata.showWelcome,
          organization: data.organization,
          defaultAvatar: data.metadata.picture!,
        };

        dispatch(login(userLogin));

        const sorterKeys = [OVERVIEW_SORTER_KEY, ARCHIVE_SORTER_KEY];

        try {
          sorterKeys.forEach((sorterKey) => {
            const destructed =
              parsedSettingsData[sorterKey as keyof UserSettingsSorters];

            if (!destructed) {
              return;
            }

            const [key, sortDirection] = destructed.split(":");
            if (key && parsedSettingsData.saveSorter) {
              dispatch(
                setActiveSorter({
                  storageKey: sorterKey,
                  state: {
                    key: key as keyof DocumentSetColumns,
                    sortDirection: parseInt(sortDirection),
                  },
                })
              );
            }
          });
        } catch (e) {
          //pass
        }

        const overviewFilters = removeUnknownInputIdsFromFilters(
          inputPermissions,
          parsedSettingsData.overviewFilters
        );
        const archiveFilters = removeUnknownInputIdsFromFilters(
          inputPermissions,
          parsedSettingsData.archiveFilters
        );

        dispatch(updateUserSettings(parsedSettingsData));
        dispatch(setFilterArrayOverview(overviewFilters));
        dispatch(setFilterArrayArchive(archiveFilters));

        if (overviewFilters?.length || archiveFilters?.length) {
          dispatch(setShouldInitFilteringData());
        }

        await changeLanguage(parsedSettingsData.language);
        await fetchAndSetOverviewConfig(userLogin, parsedSettingsData);

        Sentry.setUser({
          id: data.userProfile.id,
          email: data.userProfile.email,
          ip_address: "{{auto}}",
        });
      } catch (e) {
        dispatch(setHasColumnItemsFetchingError(true));
        console.log(e);
      }
    },
    [changeLanguage, dispatch, fetchAndSetOverviewConfig, setCookie]
  );
};

export default useAuthentication;
