import { IEntityStpTableData, IStpSupplierTableData } from "../interfaces/stp";
import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useEffect } from "react";
import {
  setStpEntities,
  setStpEntitiesDocumentType,
  setStpSuppliers,
} from "../store/organizationSlice";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import selectConfigMapEntities from "../selectors/selectConfigMapEntities";
import {
  useLazyFetchStpEntitiesQuery,
  useLazyFetchStpSuppliersQuery,
} from "../store/organizationApi";
import { OrganizationSettingsMenuKeys } from "../utils/constants";
import selectUserInputIds from "../../user/selectors/userInputIdsSelector";

const useFetchStpSettings = (type: OrganizationSettingsMenuKeys) => {
  const dispatch = useAppDispatch();

  const selectedConfigMapEntities = useAppSelector(selectConfigMapEntities);
  const { documentTypeId } = useAppSelector(
    (state) => state.organizationReducer.settings
  );
  const { user } = useAppSelector((state) => state.userReducer);

  const userInputIds = useAppSelector(selectUserInputIds);

  const [fetchStpSuppliers, { isFetching: loadingSuppliers }] =
    useLazyFetchStpSuppliersQuery();

  const [fetchStpEntities, { isFetching: loadingEntities }] =
    useLazyFetchStpEntitiesQuery();

  const fetchAndSetStpSuppliers = useCallback(async () => {
    if (!user?.organization?.id) {
      return;
    }

    try {
      const data = await fetchStpSuppliers({
        organizationId: user.organization.id,
        inputIds: userInputIds,
      }).unwrap();

      dispatch(
        setStpSuppliers({
          suppliers: data.map(
            (d): IStpSupplierTableData => ({
              ...d,
              key: d.id,
              vat: d.vatNumber!,
              source: d.input?.id!,
            })
          ),
        })
      );
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, user, fetchStpSuppliers, userInputIds]);

  const fetchAndSetStpEntities = useCallback(
    async (documentTypeId: string) => {
      try {
        const data = await fetchStpEntities(documentTypeId).unwrap();

        const stpEntitiesForDocumentType: Array<IEntityStpTableData> =
          selectedConfigMapEntities.map((cme): IEntityStpTableData => {
            const entityTypeId = cme.entityType.id;
            const found = data.find((d) => d.entityTypeId === entityTypeId);

            if (found) {
              return {
                key: found.id!,
                id: found.id,
                threshold: found.threshold,
                entityValidation: found.entityValidation,
                name: getInLanguageOrDefault(cme.entityType.translations, "en"),
                entityTypeId: found.entityTypeId,
                sources: found.sources,
                isActive: true,
              };
            }

            return {
              key: "",
              id: null,
              threshold: 100,
              entityValidation: null,
              name: getInLanguageOrDefault(cme.entityType.translations, "en"),
              entityTypeId,
              sources: [],
              isActive: true,
            };
          });

        const stpEntities: Array<IEntityStpTableData> = data.map(
          (stpEntity) => {
            const found = stpEntitiesForDocumentType.find(
              (d) => d.entityTypeId === stpEntity.entityTypeId
            );

            return {
              ...stpEntity,
              name: found?.name!,
              key: stpEntity.id!,
            };
          }
        );

        dispatch(setStpEntities({ entities: stpEntities }));
        dispatch(
          setStpEntitiesDocumentType({ entities: stpEntitiesForDocumentType })
        );
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch, selectedConfigMapEntities, fetchStpEntities]
  );

  useEffect(() => {
    if (type !== OrganizationSettingsMenuKeys.STP_SUPPLIERS) {
      return;
    }

    fetchAndSetStpSuppliers();
  }, [fetchAndSetStpSuppliers, type]);

  useEffect(() => {
    if (!documentTypeId || type !== OrganizationSettingsMenuKeys.STP_ENTITIES) {
      return;
    }

    fetchAndSetStpEntities(documentTypeId);
  }, [fetchAndSetStpEntities, documentTypeId, type]);

  return {
    loadingSuppliers,
    loadingEntities,
  };
};

export default useFetchStpSettings;
