import React, { FC, useMemo } from "react";
import { DocumentSetData } from "../../../../interfaces/documentSet";
import { Tooltip } from "antd";
import "./DocumentTypeCell.scss";

type Props = {
  documentSet: DocumentSetData;
};

const DocumentTypeCell: FC<Props> = ({ documentSet }) => {
  const documentTypeName = useMemo(() => {
    if (!documentSet.documents?.length) {
      return "N/A";
    }

    return documentSet.documents[0].documentType?.name ?? "N/A";
  }, [documentSet.documents]);

  return (
    <div className="document-type-cell__container">
      <Tooltip placement="top" title={documentTypeName}>
        <label className="document-type-cell__label">{documentTypeName}</label>
      </Tooltip>
    </div>
  );
};

export default DocumentTypeCell;
