import React, { FC, useMemo } from "react";
import GroupBlock from "./GroupBlock";
import { Alert, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { GroupBlockData, useMultipleGroupBlocks } from "../../../../configMap";
import { useAppSelector } from "../../../../app";

type GroupBlocksProps = {
  groupBlocks?: Array<GroupBlockData> | null;
  multipleLinesGroupBlocks?: Array<GroupBlockData> | null;
  disabled: boolean;
  hideNonRequiredEntities: boolean;
};

const GroupBlocks: FC<GroupBlocksProps> = ({
  groupBlocks,
  multipleLinesGroupBlocks,
  disabled,
  hideNonRequiredEntities,
}: GroupBlocksProps) => {
  const { t } = useTranslation("annotationSideControls");

  const configMapLoading = useAppSelector(
    (state) => state.configMapReducer.loading
  );

  const isTokenRefreshing = useAppSelector(
    (state) => state.userReducer.isTokenRefreshing
  );

  const { countPerGroupBlockId } = useMultipleGroupBlocks();

  const groupBlocksSorted = useMemo(
    () =>
      groupBlocks ? [...groupBlocks].sort((a, b) => a.index - b.index) : [],
    [groupBlocks]
  );

  const isLoading = useMemo(() => {
    return !groupBlocksSorted?.length && isTokenRefreshing;
  }, [groupBlocksSorted?.length, isTokenRefreshing]);

  if (configMapLoading || isLoading) {
    return (
      <div className="group-blocks__padding-container">
        <Skeleton active paragraph={{ rows: 10 }} />
      </div>
    );
  }

  if (!groupBlocksSorted.length && !multipleLinesGroupBlocks?.length) {
    return (
      <Alert
        message={t("messages.noConfigMap.title")}
        description={t("messages.noConfigMap.message")}
        type="info"
        showIcon
      />
    );
  }

  if (!groupBlocksSorted.length) {
    return (
      <Alert
        message={t("messages.noSideControlEntities.title")}
        description={t("messages.noSideControlEntities.message")}
        type="info"
        showIcon
      />
    );
  }

  return (
    <>
      {groupBlocksSorted?.map((groupBlock, index) =>
        groupBlock?.groupBlockEntityTypes?.length ? (
          <GroupBlock
            key={groupBlock.id}
            groupBlock={{
              ...groupBlock,
              index,
            }}
            disabled={disabled}
            groupBlockCount={countPerGroupBlockId(groupBlock.id)}
            hideNonRequiredEntities={hideNonRequiredEntities}
          />
        ) : null
      )}
    </>
  );
};
export default GroupBlocks;
