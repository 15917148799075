import useActiveDocument from "../../document/hooks/useActiveDocument";
import useEntityAttention from "../../configMap/hooks/useEntityAttention";
import { useMemo } from "react";
import { DEFAULT_CELL_WIDTH } from "../constants";
import { GroupBlockEntityType } from "../../configMap";
import useAnnotations from "../../annotation/hooks/useAnnotations";
import useMeasure from "react-use-measure";

const useTableEntityCell = (
  groupBlockEntityType: GroupBlockEntityType,
  groupBlockIndex: number
) => {
  const { activeDocument } = useActiveDocument();

  const { annotationsForEntity } = useAnnotations({
    documentId: activeDocument?.id,
    entityId: groupBlockEntityType.entityType.id,
    groupBlockIndex: groupBlockIndex,
  });

  const { requiresScoreAttention } = useEntityAttention(annotationsForEntity);

  const [elRef, { width: widthContainer }] = useMeasure();

  const cellWidth = useMemo(() => {
    if (!widthContainer) {
      return DEFAULT_CELL_WIDTH;
    }

    return widthContainer;
  }, [widthContainer]);

  return {
    elRef,
    requiresScoreAttention,
    cellWidth,
    isCellWidthDefined: !!widthContainer,
  };
};

export default useTableEntityCell;
