import { DocumentSetResult, SearchPageable } from "../";
import { useCallback, useEffect, useMemo, useRef } from "react";
import http from "../../common/utilities/HttpModule";
import { AxiosError, AxiosResponse } from "axios";
import {
  fetchedDocumentSets,
  setFetchingErrorType,
  stopLoading,
} from "../store/documentSetSlice";
import { OverviewType, useAppDispatch, useAppSelector } from "../../app";
import { FilterState, SortState } from "../../common/dataRefining";
import { FETCH_INTERVAL, SKELETON_LOAD_ROWS } from "../constants";
import { cleanObject } from "../../common/utilities/object";
import { mapToDtoType } from "../utils/helperFunctions";
import {
  buildFilters,
  buildSorters,
  cleanFilterString,
} from "../utils/dataFiltering";
import selectUserInputIds from "../../user/selectors/userInputIdsSelector";
import useCleanedFilter from "./useCleanedFilter";
import { DocumentSetFetchingErrorType } from "../interfaces/documentSet";

type Params = {
  overviewType: OverviewType;
  filters?: FilterState;
  sorters?: SortState | null;
  pageable?: SearchPageable;
  shouldRun?: boolean;
};

const useDocumentSets = ({
  sorters,
  overviewType,
  pageable,
  shouldRun = true,
}: Params) => {
  const lastErrorThrown = useRef("");
  const { filterString } = useCleanedFilter();
  const dispatch = useAppDispatch();

  const {
    documentSets,
    totalNumberOfDocumentSets,
    numberOfPages,
    loading,
    fetchDocumentSetsTrigger,
  } = useAppSelector((state) => state.documentSetsReducer);

  const userInputIds = useAppSelector(selectUserInputIds);

  const fetchDocumentSets = useCallback(
    (
      inputIds: Array<string>,
      signal: AbortSignal | undefined = undefined
    ): Promise<AxiosResponse<DocumentSetResult>> => {
      const cleanedFilterString = cleanFilterString(filterString, overviewType);

      return http.get(`api/v1/document-sets`, {
        signal,
        params: cleanObject({
          page: pageable!.page,
          sort: buildSorters(sorters),
          filter: buildFilters(inputIds, cleanedFilterString, overviewType),
          type: mapToDtoType(overviewType),
        }),
        paramsSerializer: (params: any) =>
          Object.keys(params)
            .map(
              (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
            )
            .join("&"),
      });
    },
    [sorters, pageable, overviewType, filterString]
  );

  const documentSetRows = useMemo(() => {
    if (loading) {
      return [...Array(SKELETON_LOAD_ROWS)].map((_, index) => ({
        id: index.toString(),
      })) as any;
    }

    return documentSets;
  }, [loading, documentSets]);

  const fetchAllDocumentSets = useCallback(
    async (signal: AbortSignal, passedType: OverviewType) => {
      if (!userInputIds.length) {
        if (loading) {
          dispatch(stopLoading());
        }
        return;
      }

      try {
        const {
          data: { totalElements, content, totalPages },
        } = await fetchDocumentSets(userInputIds, signal);

        if (passedType === overviewType) {
          dispatch(
            fetchedDocumentSets({
              documentSets: content,
              totalNumberOfDocumentSets: totalElements,
              numberOfPages: totalPages,
              selectedRows: [],
              overviewType,
              loading,
              fetchDocumentSetsTrigger: false,
              fetchingErrorType: undefined,
            })
          );
        }
        lastErrorThrown.current = "";
      } catch (e) {
        if (signal.aborted) {
          return;
        }

        const error = e as AxiosError;

        if (error?.response?.status === 400) {
          if (lastErrorThrown.current === "invalidQuery") {
            return;
          }

          dispatch(
            fetchedDocumentSets({
              documentSets: [],
              totalNumberOfDocumentSets: 0,
              numberOfPages: 1,
              selectedRows: [],
              overviewType,
              loading,
              fetchDocumentSetsTrigger: false,
              fetchingErrorType: DocumentSetFetchingErrorType.INVALID_QUERY,
            })
          );

          lastErrorThrown.current = "invalidQuery";
        } else {
          dispatch(
            setFetchingErrorType(DocumentSetFetchingErrorType.OTHER_ERROR)
          );
        }

        dispatch(stopLoading());
      }
    },
    [dispatch, fetchDocumentSets, overviewType, userInputIds, loading]
  );

  useEffect(() => {
    if (!shouldRun) {
      return;
    }
    const controller = new AbortController();
    fetchAllDocumentSets(controller.signal, overviewType!);

    const interval = setInterval(() => {
      fetchAllDocumentSets(controller.signal, overviewType!);
    }, FETCH_INTERVAL);

    return () => {
      clearInterval(interval);
      controller.abort();
    };
  }, [fetchAllDocumentSets, overviewType, shouldRun, fetchDocumentSetsTrigger]);

  return {
    documentSets,
    documentSetRows,
    totalNumberOfDocumentSets,
    numberOfPages,
    loading,
  };
};

export default useDocumentSets;
