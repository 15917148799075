import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";
import http from "../../common/utilities/HttpModule";
import useAuthenticationCookie from "./useAuthenticationCookie";

//@ts-ignore
export const isAlternativeFlow = !!window.__JWT_TOKEN__;

const useIsAuthenticated = () => {
  const { isAuthenticated: auth0Authenticated, isLoading: auth0Loading } =
    useAuth0();
  const { cookie } = useAuthenticationCookie();

  const isAuthenticated = useMemo(() => {
    if (cookie) {
      http.axios.defaults.headers.common.Authorization = `Bearer ${cookie}`;

      if (isAlternativeFlow) {
        return true;
      }
    }

    return auth0Authenticated;
  }, [auth0Authenticated, cookie]);

  const isLoading = useMemo(() => {
    if (isAuthenticated) {
      // If a user is authenticated, an access token cookie must be present.
      return auth0Loading || !cookie;
    }

    return auth0Loading;
  }, [auth0Loading, cookie, isAuthenticated]);

  return { isLoading, isAuthenticated };
};

export default useIsAuthenticated;
