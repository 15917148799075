import React, { FC } from "react";
import { GroupBlockEntityType } from "../../../configMap";
import EntityCell from "./entityCell/EntityCell";
import useTableEntityCell from "../../hooks/useTableEntityCell";

type Props = {
  groupBlockEntityType: GroupBlockEntityType;
  groupBlockIndex: number;
  disabled: boolean;
};

const TableEntityCell: FC<Props> = ({
  groupBlockEntityType,
  groupBlockIndex,
  disabled,
}) => {
  const { elRef, requiresScoreAttention, cellWidth, isCellWidthDefined } =
    useTableEntityCell(groupBlockEntityType, groupBlockIndex);

  return (
    <div ref={elRef}>
      <EntityCell
        groupBlockEntityType={groupBlockEntityType}
        groupBlockIndex={groupBlockIndex}
        disabled={disabled}
        requiresAttention={requiresScoreAttention}
        cellWidth={cellWidth}
        isCellWidthDefined={isCellWidthDefined}
      />
    </div>
  );
};

export default TableEntityCell;
