import React, { FC, Key, useState } from "react";
import { Button, Popconfirm } from "antd";
import StpSupplierTableFooterAdd from "./StpSupplierTableFooterAdd";
import { useTranslation } from "react-i18next";

type Props = {
  selectedRows: Array<Key>;
  setSelectedRows: (ids: Array<string>) => void;
  removeSuppliers: (ids: Array<string>) => void;
};

const StpSupplierTableFooter: FC<Props> = ({
  selectedRows,
  setSelectedRows,
  removeSuppliers,
}) => {
  const { t } = useTranslation("stp");

  const [isAdding, setIsAdding] = useState(false);

  if (isAdding) {
    return <StpSupplierTableFooterAdd handleCancel={setIsAdding} />;
  }

  if (selectedRows.length) {
    return (
      <div className="selected-rows-actions">
        <div>
          <Button type="text" icon={<span className="bi bi-plus-square" />}>
            {t("suppliers.addSuppliers")}
          </Button>
        </div>
        <div>
          <Popconfirm
            title={t("deleteSuppliersModal.title")}
            description={t("deleteSuppliersModal.description")}
            onConfirm={() =>
              removeSuppliers(selectedRows.map((s) => s.toString()))
            }
            okText="Yes"
            cancelText="No"
            icon={
              <span
                className="bi bi-exclamation-circle"
                style={{ marginRight: "5px", color: "red" }}
              />
            }
          >
            <Button danger type="text" icon={<span className="bi bi-trash" />}>
              {t("suppliers.deleteSelectedRows")}
            </Button>
          </Popconfirm>
          <Button type="text" onClick={() => setSelectedRows([])}>
            {t("suppliers.cancel")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Button
      type="text"
      icon={<span className="bi bi-plus-square" />}
      onClick={() => setIsAdding(true)}
    >
      {t("suppliers.addSuppliers")}
    </Button>
  );
};

export default StpSupplierTableFooter;
