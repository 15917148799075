import React, { FC, HTMLAttributes, useMemo } from "react";
import "./AnnotationTableControls.scss";
import { Skeleton, Table } from "antd";
import {
  GroupBlockData,
  GroupBlockEntityType,
  useHotKeyListener,
} from "../../configMap";
import GroupBlockTabs from "./groupBlockTabs/GroupBlockTabs";
import {
  GROUPBLOCK_TABS_HEIGHT,
  SINGLE_ROW_HEIGHT,
  TABLE_HEADER_HEIGHT,
} from "../constants";
import useAnnotationTableControls from "../hooks/useAnnotationTableControls";
import HeaderActions from "./headerActions/HeaderActions";
import Actions from "./actions/Actions";
import useHighlightEntities from "../../annotation/hooks/useHighlightEntities";
import TableEntityCell from "./tableEntityCell/TableEntityCell";
import CategorizationCell from "./tableCategorizationCell/CategorizationCell";
import EntityHotKey from "./tableEntityCell/entityCell/entityAnnotation/EntityHotkey";
import { useInView } from "react-intersection-observer";
import { useAppSelector } from "../../app";
import selectHighlightedIndex from "../../annotation/selectors/selectHighlightedIndex";
import LinkIcon from "../../metadata/components/categorization/LinkIcon";
import EntityTitle from "../../configMap/components/entity/EntityTitle";

type Props = {
  disabled: boolean;
  multipleLinesGroupBlocks: Array<GroupBlockData>;
  hideNonRequiredEntities: boolean;
  handleResizing: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const AnnotationTableControls: FC<Props> = ({
  disabled,
  multipleLinesGroupBlocks,
  hideNonRequiredEntities,
  handleResizing,
}) => {
  const { highlightEntitiesInGroupBlock } = useHighlightEntities();

  const {
    selectedGroupBlockForTable,
    selectedGroupBlock,
    addGroupBlockLineAction,
    configMapLoading,
    refHeightContainer,
    currentHeight,
  } = useAnnotationTableControls(disabled, multipleLinesGroupBlocks);

  useHotKeyListener(
    hideNonRequiredEntities,
    disabled,
    selectedGroupBlockForTable?.groupBlock.groupBlockEntityTypes,
    selectedGroupBlockForTable?.groupBlock.multipleGroupBlocks
  );

  const actionColumnItem = useMemo(() => {
    if (!selectedGroupBlock) {
      return null;
    }

    return {
      title: (
        <HeaderActions
          groupBlock={selectedGroupBlock}
          index={selectedGroupBlock?.index}
          disabled={disabled}
        />
      ),
      key: "actions",
      width: 55,
      fixed: "right",
      render: (_: any, props: any) => {
        return (
          <Actions
            groupBlock={props.groupBlock}
            index={props.index}
            amountOfLines={props.count}
            disabled={disabled}
            addGroupBlock={() => {
              addGroupBlockLineAction(props.groupBlock, props.index);
            }}
          />
        );
      },
    };
  }, [selectedGroupBlock, addGroupBlockLineAction, disabled]);

  const columnItems = useMemo(() => {
    if (!selectedGroupBlockForTable) {
      return [];
    }

    const entityColumnItems = selectedGroupBlockForTable.entityColumnItems.map(
      (item) => {
        return {
          title: (
            <EntityTitle groupBlockEntityType={item.groupBlockEntityType} />
          ),
          key: item.key,
          dataIndex: item.key,
          width: item.width,
          render: (_: any, { index }: any) => (
            <TableEntityCell
              groupBlockEntityType={item.groupBlockEntityType}
              groupBlockIndex={index}
              disabled={item.disabled}
            />
          ),
        };
      }
    );
    const categorizationColumnItems =
      selectedGroupBlockForTable.categorizationColumnItems.map((item) => {
        return {
          title: (
            <>
              <LinkIcon
                categorization={item.categorizationWithConfig.categorization}
                isTableControls={true}
              />
              <span>{item.title}</span>
            </>
          ),
          key: item.key,
          dataIndex: item.key,
          width: item.width,
          render: (_: any, { index }: any) => (
            <CategorizationCell
              key={item.categorizationCellKey}
              disabled={item.disabled}
              categorizationWithConfig={item.categorizationWithConfig}
              groupBlockId={item.groupBlockId}
              index={index}
            />
          ),
        };
      });

    const items: Array<any> =
      categorizationColumnItems.concat(entityColumnItems);

    items.unshift({
      title: "",
      key: "index",
      width: 20,
      fixed: "left",
      render: (_: any, props: any) => (
        <div
          onClick={() =>
            highlightEntitiesInGroupBlock(props.index, props.groupBlock)
          }
          className="index-column"
        >
          <h4 className="index-column-text">{props.index}</h4>
        </div>
      ),
    });
    items.push(actionColumnItem);

    return items;
  }, [
    selectedGroupBlockForTable,
    actionColumnItem,
    highlightEntitiesInGroupBlock,
  ]);

  if (configMapLoading) {
    return (
      <div className="annotation-table-controls-skeleton">
        <div className="header-skeleton" />
        <div className="body-skeleton">
          <Skeleton active paragraph={{ rows: 5 }} />
        </div>
      </div>
    );
  }

  return (
    <div
      className="annotation-table-controls-container"
      ref={refHeightContainer}
    >
      <GroupBlockTabs
        handleResizing={handleResizing}
        multipleLinesGroupBlocks={multipleLinesGroupBlocks}
      />
      <div className="group-blocks-container">
        <Table
          className="group-block-table"
          dataSource={selectedGroupBlockForTable?.data}
          size="small"
          pagination={false}
          columns={columnItems}
          rowKey={(record) => record.index}
          scroll={{
            y: `calc(${
              currentHeight - GROUPBLOCK_TABS_HEIGHT - TABLE_HEADER_HEIGHT
            }px)`,
          }}
          components={{
            body: {
              row: TableRow,
            },
          }}
        />
      </div>
    </div>
  );
};

interface RowProps extends HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": number;
}

const TableRow: FC<RowProps> = (props) => {
  const highlightedIndex = useAppSelector(selectHighlightedIndex);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const firstColumn = useMemo(() => {
    if (!props.children) {
      return undefined;
    }

    // @ts-ignore
    return props.children[0]?.props?.record;
  }, [props.children]);

  if (!inView) {
    const children = React.Children.toArray(props.children);

    return (
      <>
        <tr
          {...props}
          ref={ref}
          className="loading-row"
          style={{ height: `${SINGLE_ROW_HEIGHT}px` }}
        >
          {children?.map((child, index) =>
            index === 0 ? (
              child // Show the index column when loading
            ) : (
              <td className={`skeleton-loader`} key={index}>
                <Skeleton paragraph={false} active />
              </td>
            )
          )}
        </tr>
        {firstColumn?.groupBlock?.groupBlockEntityTypes
          ? firstColumn.groupBlock.groupBlockEntityTypes.map(
              (item: GroupBlockEntityType, index: number) => {
                return (
                  <span key={index} className="invisible-hotkey">
                    <EntityHotKey
                      isLoading={true}
                      groupBlockEntityType={item}
                      groupBlockIndex={firstColumn.index}
                      disabled={false}
                    />
                  </span>
                );
              }
            )
          : null}
      </>
    );
  }

  if (props["data-row-key"] === highlightedIndex) {
    return (
      <tr {...props} ref={ref} className={`${props.className} highlighted-row`}>
        {props.children}
      </tr>
    );
  }

  return (
    <tr {...props} ref={ref}>
      {props.children}
    </tr>
  );
};

export default AnnotationTableControls;
