import axios from "axios";
import { getVariableFromEnv } from "./env";
import { store } from "../../app";
import {
  changeForceTokenReFetch,
  changeIsTokenRefreshing,
} from "../../user/store/userSlice";

axios.defaults.baseURL = getVariableFromEnv("API_BASE_URL");

axios.interceptors.response.use(undefined, (error: any) => {
  if (error.response?.status === 401) {
    store.dispatch(changeForceTokenReFetch(true));
    store.dispatch(changeIsTokenRefreshing(true));
  }

  const isExpectedError =
    error.response?.status >= 404 && error.response?.status < 500;

  if (!isExpectedError && !axios.isCancel(error)) {
    console.log("Unexpected error: " + error);
  }

  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  axios,
};
